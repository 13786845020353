/* Base styles for the header */
.header {
  display: flex;

  justify-content: space-between;
  align-items: center;
  padding: 30px 80px;
  background-color: #ffffff;
  position: relative; /* Make header a relative container */
  height: 80px; /* Adjust as needed */
  border-bottom: 1px solid #efefef;
}

/* Logo Styles */
.logo {
  flex: 1;
}
.logo-adjust {
  margin-bottom: 2px;
}
.logo img {
  height: 160px;
}

/* Navigation Styles */
.nav {
  position: absolute; /* Position navigation absolutely within header */
  bottom: 0; /* Align to bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  display: flex;
  justify-content: center;
  align-items: left;
  color: #052472;
  transition: transform 0.3s ease-in-out;
}

.nav ul {
  display: flex;
  list-style: none;
  padding: 0;

  color: #052472;
}

.nav ul li {
  margin: 0 15px;
}

.nav ul li a {
  text-decoration: none;
  color: #052472;
}

.nav ul li a:hover {
  text-decoration: underline; /* Add underline on hover */
}

/* Contact Details Styles */
.contact {
  flex: 1;
  text-align: right;
  font-size: 14px;
  color: #052472;
}

/* Mobile Styles */
@media (max-width: 64rem) {
  .header {
    align-items: flex-start;
    padding: 10px 40px;
    height: auto; /* Adjust height for mobile view */
  }

  .logo img {
    height: 100px; /* Adjust logo size for mobile view */
  }

  .nav {
    position: static; /* Remove absolute positioning on mobile */
    transform: none; /* Remove transform on mobile */
    width: 100%; /* Ensure full width */
    justify-content: center;
    margin-top: 10px; /* Add margin for spacing */
  }

  .nav ul {
    flex-direction: column;
    align-items: center;
  }

  .nav ul li {
    margin: 0px 0;
  }

  .contact {
    display: none; /* Hide contact details on mobile */
  }
}

/* Mobile Toggle Menu Styles */
.menu-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-toggle .bar {
  width: 35px;
  height: 3px;
  background-color: #062471;
  margin: 4px 0;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 64rem) {
  .menu-toggle {
    display: flex;
    margin-top: 35px;
  }
  .menu-toggle .close-bar:nth-child(1) {
    transform: rotate(45deg) translate(7.5px, 7.5px);
  }

  .menu-toggle .close-bar:nth-child(2) {
    opacity: 0;
  }

  .menu-toggle .close-bar:nth-child(3) {
    transform: rotate(-45deg) translate(7.5px, -7.5px);
  }

  .nav {
    width: 100%;
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background: #931a1a;
  }

  /* Styles for the open navigation state */
  .nav.open {
    display: flex;
    flex-direction: column; /* Stack links vertically in open state */
    height: 170px;
    background-color: rgb(255, 255, 255);
    margin-top: 40px;
    padding-bottom: 10px;
    z-index: 20; /* Ensure the open nav is above other elements */
  }

  /* Specific styles for links within the open navigation */
  .nav.open ul {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 20; /* Ensure the open nav is above other elements */
  }

  .nav.open ul li {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 20; /* Ensure the open nav is above other elements */
    border-bottom: solid 1px #052472;
  }

  .nav.open ul li a {
    font-size: 18px; /* Increase font size */
    border-radius: 2px; /* Add rounded corners */
    transition: background-color 0.3s; /* Smooth transition for background color */
    z-index: 20; /* Ensure the open nav is above other elements */
  }

  /* Hover effect for links in the open navigation state */
  .nav.open ul li a:hover {
    background-color: #0056b3; /* Change background color on hover */
    color: white; /* Change text color on hover */
    z-index: 20; /* Ensure the open nav is above other elements */
  }
}

/* Make banner transition Styles */
/* Banner.css */
.banner {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 400px; /* Adjust as needed */
  overflow: hidden;
}

.banner-image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.banner-image.active {
  opacity: 1;
}

.banner-image.prev::before,
.banner-image.active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: all 1s ease-in-out;
  transform-origin: left;
  clip-path: inset(0 100% 0 0);
}

.banner-image.active::before {
  clip-path: inset(2 5 0 0);
}

/* Make Footer Styles */
.footer {
  align-content: center;
  text-align: center;
  border-top: 1px solid #cbcbcb;
  background-color: #f5f5f5;
  height: 150px;
}
/* Make Utilities  Styles */
.container {
  max-width: 1100px;
  margin: 0 auto; /* Center horizontally */
  overflow: hidden;
  padding: 30px 80px;
  margin-top: 1rem;
  margin-bottom: 3rem;
}
/* Make contact us page  Styles */
.contact-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 20px 0;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.contact-address {
  flex: 1;
  margin-right: 20px;
  min-width: 250px; /* Ensure a minimum width for the address */
}

.contact-map {
  flex-shrink: 0;
}

.map-image {
  max-width: 300px;
  width: 100%;
  height: auto;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column; /* Stack the items vertically */
    align-items: center; /* Center the items horizontally */
  }

  .contact-address {
    margin-right: 0;
    margin-bottom: 20px; /* Add spacing between the address and the map */
    text-align: center; /* Center text for better readability on mobile */
  }

  .contact-map {
    width: 100%; /* Ensure the map takes full width */
    max-width: none; /* Remove the max-width restriction */
  }
  h1 {
    text-align: center;
    width: 100%; /* Ensure the h1 takes full width for proper centering */
  }
}

.svg_container {
  display: flex;
  justify-content: center;

  align-items: center;
  position: relative; /* Make header a relative container */
}
.svg_container1 {
  margin: 20px 20px;
}
